// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/profile.png";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import sass from "./assets/techstack/sass.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import figma from "./assets/techstack/figma.png";
// Porject Images
import projectImage1 from "./assets/projects/project1.jpg";
import projectImage2 from "./assets/projects/project2.jpg";
import projectImage3 from "./assets/projects/project3.jpg";
import projectImage4 from "./assets/projects/project4.jpg";
import projectImage5 from "./assets/projects/project5.jpg";
import projectImage6 from "./assets/projects/project6.jpg";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Rusiru Nethmina",
  tagline: "I build things for Real World",
  img: profile,
  about: `Experienced Full Stack Engineer with a demonstrated history of working in the information technology and services industry. Skilled in Full-Stack Development &  Mobile Applications. Strong engineering professional with a BSC in Management Information System focused in Computer Software Engineering from NSBM Green University Town.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/rusiru-nethmina-360041203/",
  github: "https://github.com/rusirunethmina?tab=repositories/",
  twitter: "https://twitter.com/",
  instagram: "https://www.instagram.com/",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Full Stack Software Engineer",
    Company: `Pixel Pro Oman`,
    Location: "Masqaţ, Oman",
    Type: "Full Time - onsite",
    Duration: "Apr 2023 - Present",
  },
    {
    Position: "Associate Full Stack Software Engineer ",
    Company: `Celata Tech`,
    Location: "Sri Lanka",
    Type: "Full Time - onsite",
    Duration: "Jan 2022 - Dec 2022",
  },
    {
    Position: "Full-stack Web Developer",
    Company: `Donext`,
    Location: "Sri Lanka",
    Type: "Part-time - remote",
    Duration: "Aug 2022 - Present",
  },
  {
    Position: "Full-stack Web Developer",
    Company: `InnoCube `,
    Location: "Sri Lanka",
    Type: "Part-time - remote",
    Duration: "Sep 2021 - Dec 2021",
  },
  {
    Position: "Full Stack Developer (Internship)",
    Company: `AppsDept`,
    Location: "Kandy, Central, Sri Lanka",
    Type: "Full Time - remote",
    Duration: "May 2021 - Oct 2021",
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "NSBM Green University",
    Company: "Higher Education",
    Location: "Mahenawatta, Pitipana, Homagama, Colombo",
    Type: "Full Time",
    Duration: "Mar 2019 - Present",
  },
  {
    Position: "Sri Lanka Institute of Marketing-SLIM",
    Company: `Diploma`,
    Location: "No. 94 Ananda Rajakaruna Mawatha, Colombo",
    Type: "Part Time",
    Duration: "Dec 2022 - Present",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "Project title 1",
    image: projectImage1,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 2",
    image: projectImage2,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 3",
    image: projectImage3,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 4",
    image: projectImage4,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 5",
    image: projectImage5,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 6",
    image: projectImage6,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
];

// Enter your Contact Details here
export const contactDetails = {
  email: "rusiruc21@gmail.com",
  phone: "+986 78194496",
};
